import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { OTPDataModel, PhoneDataModel } from 'src/app/core/domain/auth/auth.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { RequestOTPUseCase } from 'src/app/core/usecases/auth/request-otp.usecase';
import { SetRegisterTokenUseCase } from 'src/app/core/usecases/auth/set-register-token.usecase';
import { GetUserUseCase } from 'src/app/core/usecases/user/get-user2.usecase';
import { SetUserUseCase } from 'src/app/core/usecases/user/set-user-usecase';
import { SharedNotificationConfig } from 'src/app/presentation/auth/shared/notification/shared.notification.component';
import { SignupOTPComponent } from 'src/app/presentation/auth/signup/components/signup-otp/signup-otp.component';
import { MetaPhoneNumberOutput } from 'src/app/presentation/shared/components/meta-phone-number-field/meta-phone-number-field.component';
import { CurrentUserStep } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { AUTH_CONSTS } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { SharedOverlayService } from 'src/app/presentation/shared/services/shared-overlay.service';
import { SharedNotificationComponent } from '../../../auth/shared/notification/shared.notification.component';
import { MetaPhoneNumberFieldComponent } from '../../../shared/components/meta-phone-number-field/meta-phone-number-field.component';

@Component({
  selector: 'app-opt-in-otp',
  templateUrl: './opt-in-otp.component.html',
  styleUrls: ['./opt-in-otp.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    SharedNotificationComponent,
    FormsModule,
    ReactiveFormsModule,
    MetaPhoneNumberFieldComponent,
    AsyncPipe,
  ],
})
export class OptInOtpComponent implements AfterViewInit, OnDestroy {
  public authAssetsRelativePath = '../../../assets/img/auth/';

  public currentOTPStep$: Subject<'optInEnterPhoneNumber' | 'optInEnterOTP'> = new Subject<
    'optInEnterPhoneNumber' | 'optInEnterOTP'
  >();

  public phonePrefix: string;

  private countryCode: string;

  public enterPhoneNumberForm: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  public responseMessage: SharedNotificationConfig;

  public otpCheckCode = 'lorem';

  onDestroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('outlet', { read: ViewContainerRef })
  private _outlet: ViewContainerRef;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private _requestOTPUseCase: RequestOTPUseCase,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sharedOverlayService: SharedOverlayService,
    private _localStorageService: LocalStorageService,
    private _setRegisterTokenUseCase: SetRegisterTokenUseCase,
    private _setUserUseCase: SetUserUseCase,
    private _getUserUseCase: GetUserUseCase,
  ) {}

  ngAfterViewInit(): void {
    this.currentOTPStep$.next('optInEnterPhoneNumber');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public phoneNumberChanges(ev: MetaPhoneNumberOutput) {
    this.phonePrefix = ev.country.phoneNumPrefix.toString();
    this.enterPhoneNumberForm.clearValidators();
    this.enterPhoneNumberForm.patchValue(ev.phoneNumber);
    this.enterPhoneNumberForm.addValidators(Validators.required);
    this.enterPhoneNumberForm.addValidators(Validators.pattern(ev.country.phoneRegex));
    this.countryCode = ev.country.isoCode3;
  }

  onSubmit(): void {
    const phoneData: PhoneDataModel = {
      phoneNumber: this.enterPhoneNumberForm.value.toString(),
      callingCode: this.phonePrefix,
    };
    this._requestOTPUseCase.execute(phoneData).subscribe({
      next: (res: OTPDataModel) => {
        if (res.checkCode) {
          this._handleCoveredCountries(res);
        } else {
          this._handleUncoveredCountries(res.jwtToken!);
        }
        const payload = this.enterPhoneNumberForm
          ? {
              phone_prefix: this.phonePrefix,
              phone_number: this.enterPhoneNumberForm.value,
            }
          : {};
        this._logMixpanelEventUseCase.execute({
          eventName: 'opt_in_otp_phone_number_submit_click',
          payload,
        });
      },
      error: (err) => {
        this.responseMessage = {
          msg:
            err.status === 500
              ? 'رقم الهاتف غير صحيح'
              : AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err.error.errorCode)!,
          status: 'error',
          iconMeta: {
            icon: 'assets/img/auth/danger-white.svg',
            position: 'before',
          },
        };
        this._logMixpanelEventUseCase.execute({
          eventName: 'opt_in_otp_phone_number_submit_error',
          payload: {
            'error-message': err.error.errorCode,
          },
        });
      },
    });
  }

  private _handleCoveredCountries(res: OTPDataModel): void {
    this.otpCheckCode = res.checkCode!;
    this.currentOTPStep$.next('optInEnterOTP');
    this._changeDetectorRef.detectChanges();
    this._injectOTPComponentAndHandleEventsFromIt(
      this.phonePrefix,
      this.enterPhoneNumberForm.value.toString(),
      this.countryCode,
    );
  }

  private _handleUncoveredCountries(token: string): void {
    const currentUserOnStorage = this._getUserUseCase.execute();
    this._setUserUseCase.execute({
      ...currentUserOnStorage!,
      token,
    });
    this._setRegisterTokenUseCase.execute(token);
    this._sharedOverlayService.setOverlayType('optInCountryNotCovered');
  }

  private _injectOTPComponentAndHandleEventsFromIt(
    phoneNumberPrefix: string,
    userPhoneNumber: string,
    userCountryCode: string,
  ): void {
    const componentRef = this._outlet.createComponent(SignupOTPComponent);
    componentRef.instance.scope = 'opt-in';
    componentRef.instance.phonePrefix = phoneNumberPrefix;
    componentRef.instance.userPhoneNumber = userPhoneNumber;
    componentRef.instance.userCountryCode = userCountryCode;
    componentRef.instance.otpCheckCode = this.otpCheckCode;
    componentRef.changeDetectorRef.detectChanges();
    componentRef.instance.goToNextStep$.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (status: Extract<CurrentUserStep, 'optInSuccessVerifyingOTP'>) => {
        switch (status) {
          case 'optInSuccessVerifyingOTP':
            this._sharedOverlayService.setOverlayType('success');
            break;
        }
      },
    });
  }
}
